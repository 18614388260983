import React from 'react'
import { graphql } from 'gatsby'
import { undefIfEmpty } from '@walltowall/helpers'
import { Box } from '@walltowall/calico'

import { PageBodyStartsWithFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { Text } from '../components/Text'
import { Divider } from '../components/Divider'
import { Columns } from '../components/Columns'

type CardProps = {
  eyebrow?: string
  text?: string
}

const Card = ({ eyebrow, text }: CardProps) => {
  return (
    <Box>
      <Divider
        color="gold70"
        styles={{
          height: ['2px', null, '3px'],
          width: ['1rem', '1.875rem'],
          marginBottom: [4, 6, 8],
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />

      <Text
        component="h4"
        variant="sans-caps-bold-13-18"
        styles={{
          color: 'fuschia30',
          marginBottom: [4, 5, 7],
          textAlign: 'center',
        }}
      >
        {eyebrow}
      </Text>

      <Text
        component="p"
        variant="serif-medium-italic-22-40"
        style={{ maxWidth: '17ch' }}
        styles={{
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {text}
      </Text>
    </Box>
  )
}

export type PageBodyStartsWithProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyStartsWith = ({
  children,
  ...props
}: PageBodyStartsWithProps) => (
  <BoundedBox
    variant="wide"
    component="section"
    styles={{
      maxWidth: 'xlarge',
      marginRight: 'auto',
      marginLeft: 'auto',
      backgroundColor: 'white',
      color: 'brown20',
    }}
    {...props}
  >
    <Columns count={[1, 3]} space={8}>
      {children}
    </Columns>
  </BoundedBox>
)
PageBodyStartsWith.Card = Card

export const mapDataToProps = ({
  data,
  nextType,
}: MapDataToPropsArgs<
  PageBodyStartsWithFragment,
  typeof mapDataToContext
>) => ({
  nextSharesBg:
    nextType === 'PageBodyProfilesList'
      ? [false, false, false, false]
      : undefined,
  children: data.items?.map((item, idx) => (
    <PageBodyStartsWith.Card
      key={idx}
      eyebrow={undefIfEmpty(item?.eyebrow?.text)}
      text={undefIfEmpty(item?.text?.text)}
    />
  )),
})

export const mapDataToContext = () => ({
  bg: 'white',
})

export const fragment = graphql`
  fragment PageBodyStartsWith on PrismicPageBodyStartsWith {
    items {
      eyebrow {
        text
      }
      text {
        text
      }
    }
  }
`

export default PageBodyStartsWith
