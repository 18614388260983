import * as React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import { FluidObject } from 'gatsby-image'
import { Box, usePseudoBoxStyles, useBoxStyles } from '@walltowall/calico'
import { AspectRatio } from '@walltowall/siamese'
import { getRichText, undefIfEmpty } from '@walltowall/helpers'
import clsx from 'clsx'

import { PageBodyProfilesListFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { ProfileTemplateEnhancerProps } from '../templates/profile'

import { BoundedBox } from '../components/BoundedBox'
import { Columns } from '../components/Columns'
import { GatsbyImageContainer } from '../components/GatsbyImageContainer'
import { Link } from '../components/Link'
import { Text } from '../components/Text'
import { HTMLContent } from '../components/HTMLContent'
import { Divider } from '../components/Divider'

import * as styleRefs from './PageBodyProfilesList.treat'

export type PageBodyProfilesListProps = ReturnType<typeof mapDataToProps> &
  ProfileTemplateEnhancerProps

const PageBodyProfilesList = ({
  heading,
  textHTML,
  children,
  nextSharesBg,
  withTopDivider,
}: PageBodyProfilesListProps) => (
  <BoundedBox
    component="section"
    nextSharesBg={nextSharesBg}
    styles={{
      backgroundColor: 'white',
      color: 'brown20',
      maxWidth: 'xlarge',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: withTopDivider ? 0 : [10, 20, 28],
    }}
  >
    {withTopDivider && (
      <Divider
        color="gold70"
        styles={{ height: '2px', marginBottom: [6, 8, 11] }}
      />
    )}
    {(heading || textHTML) && (
      <Box
        styles={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          marginBottom: [11, 14],
        }}
      >
        <Box
          styles={{
            width: [null, '4/12', '6/12'],
            flexShrink: 0,
            marginRight: [null, 5, 6],
          }}
        >
          {heading && (
            <Text
              variant="sans-30-52"
              styles={{ color: 'beige40', marginBottom: [5, 0] }}
            >
              {heading}
            </Text>
          )}
        </Box>
        {textHTML && (
          <HTMLContent
            html={textHTML}
            styles={{ flexGrow: [null, 1], alignSelf: [null, 'end'] }}
          />
        )}
      </Box>
    )}
    <Columns count={[2, 3]} space={[4, 5, 6]} spaceY={[8, 11, 14]}>
      {children}
    </Columns>
  </BoundedBox>
)

type PageBodyProfilesListProfileProps = {
  name?: string
  title?: string
  href: string
  imageFluid?: FluidObject
  imageAlt?: string
}

const PageBodyProfilesListProfile = ({
  name,
  title,
  href,
  imageFluid,
  imageAlt,
}: PageBodyProfilesListProfileProps) => {
  const fadeOnFocus = clsx(
    useBoxStyles({
      display: 'block',
      transitionDuration: 'normal',
      transitionTimingFunction: 'easeOut',
      transitionProperty: 'opacity',
    }),
    usePseudoBoxStyles({ opacity: 75 }, 'focus'),
    usePseudoBoxStyles({ opacity: 75 }, 'hover'),
  )

  return (
    <Box className={styleRefs.focusParent}>
      <Link
        href={href}
        tabIndex={-1}
        className={clsx(fadeOnFocus, styleRefs.fadeOnFocus)}
      >
        <GatsbyImageContainer styles={{ backgroundColor: 'brown20' }}>
          <AspectRatio x={7} y={8}>
            {imageFluid && (
              <GatsbyImage
                fluid={imageFluid}
                alt={imageAlt || `${name}, ${title}`}
              />
            )}
          </AspectRatio>
        </GatsbyImageContainer>
      </Link>
      {title && (
        <Text
          component="p"
          variant="sans-16-24"
          styles={{ marginTop: [3, 4, 5], marginBottom: [2.5, 3.5, 5] }}
        >
          <Link href={href}>{title}</Link>
        </Text>
      )}
      {name && (
        <Text component="p" variant="sans-caps-semibold-13-18">
          {name}
        </Text>
      )}
    </Box>
  )
}
PageBodyProfilesList.Profile = PageBodyProfilesListProfile

export const mapDataToProps = ({
  data,
  context,
  previousContext,
}: MapDataToPropsArgs<
  PageBodyProfilesListFragment,
  typeof mapDataToContext
>) => ({
  withTopDivider:
    (undefIfEmpty(data.primary?.heading?.text) ||
      undefIfEmpty(data.primary?.text?.text)) &&
    context?.bg === previousContext?.bg,
  heading: data.primary?.heading?.text,
  textHTML: getRichText(data?.primary?.text),
  children: data.items
    ?.map?.((item) => {
      const profile = item?.profile?.document

      return (
        profile?.url && (
          <PageBodyProfilesList.Profile
            key={profile?.url}
            href={profile?.url}
            name={profile?.data?.title?.text}
            title={profile?.data?.profile_title?.text}
            imageFluid={profile?.data?.featured_image?.fluid}
            imageAlt={profile?.data?.featured_image?.alt}
          />
        )
      )
    })
    .filter?.(Boolean),
})

export const mapDataToContext = () => ({
  bg: 'white',
})

export const fragment = graphql`
  fragment PageBodyProfilesList on PrismicPageBodyProfilesList {
    primary {
      heading {
        text
      }
      text {
        text
        html
      }
    }
    items {
      profile {
        document {
          ... on PrismicProfile {
            url
            data {
              title {
                text
              }
              profile_title {
                text
              }
              featured_image {
                alt
                fluid(maxWidth: 400) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PageBodyProfilesList
