import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'

import { PageBodyTextFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

export type PageBodyTextProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyText = ({ textHTML, nextSharesBg }: PageBodyTextProps) => (
  <BoundedBox
    component="section"
    variant="base"
    nextSharesBg={nextSharesBg}
    styles={{
      backgroundColor: 'white',
      color: 'brown20',
      maxWidth: 'xlarge',
      marginLeft: 'auto',
      marginRight: 'auto',
    }}
  >
    {textHTML && (
      <HTMLContent
        html={textHTML}
        componentOverrides={{
          p: (Comp) => (props) => <Comp variant="sans-18-28" {...props} />,
          h3: (Comp) => (props) =>
            (
              <Comp
                component="h3"
                variant="sans-caps-semibold-13-18"
                styles={{
                  color: 'fuschia30',
                  textAlign: 'center',
                  marginBottom: [4, 6, 8],
                }}
                {...props}
              />
            ),
        }}
        styles={{
          textAlign: 'center',
          width: [null, '10/12', '9/12'],
          marginLeft: [null, 'auto'],
          marginRight: [null, 'auto'],
        }}
      />
    )}
  </BoundedBox>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyTextFragment, typeof mapDataToContext>) => ({
  textHTML: getRichText(data?.primary?.text),
})

export const mapDataToContext = () => ({
  bg: 'white',
})

export const fragment = graphql`
  fragment PageBodyText on PrismicPageBodyText {
    primary {
      text {
        text
        html
      }
    }
  }
`

export default PageBodyText
