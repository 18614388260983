import React from 'react'
import { FieldText, FieldTextProps } from './FieldText'

export const FieldTextarea = React.forwardRef<HTMLElement, FieldTextProps>(
  (props, ref) => (
    <FieldText
      component="textarea"
      ref={ref}
      {...props}
      styles={{ height: '6rem', ...props.styles }}
    />
  ),
)
