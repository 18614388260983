import React from 'react'
import { Helmet } from 'react-helmet-async'
import { SkipNavLink } from '@reach/skip-nav'
import { Box } from '@walltowall/calico'

import { Text } from './Text'
import { DevPanel } from './DevPanel'

import { useSiteSettings } from '../hooks/useSiteSettings'

export type LayoutProps = {
  children?: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
  const settings = useSiteSettings()

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{settings.siteName}</title>
        <meta name="description" content={settings.siteDescription} />
        <link
          rel="stylesheet"
          href="https://cloud.typography.com/6033652/7716392/css/fonts.css"
        />
      </Helmet>
      <SkipNavLink>
        <Text variant="sans-16-24">Skip to content</Text>
      </SkipNavLink>
      <Box
        component="main"
        styles={{ backgroundColor: 'beige100', color: 'brown20' }}
      >
        {children}
      </Box>
      {process.env.NODE_ENV === 'development' && <DevPanel />}
    </>
  )
}
