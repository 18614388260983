/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { useStyles } from 'react-treat'
import { Box, BoxProps } from '@walltowall/calico'
import HTMLRenderer, { HTMLRendererProps } from 'react-html-renderer'
import clsx from 'clsx'

import { Anchor } from './Anchor'
import { Text } from './Text'

import { useUtilStyles } from '../hooks/useUtilStyles'

import * as styleRefs from './HTMLContent.treat'

const baseHeadingStyles = {
  marginTop: [8, 9, 18],
  marginBottom: [5, 7, 9],
} as const

const baseTextStyles = {
  marginBottom: [6, 7, 9],
} as const

const components: HTMLRendererProps['components'] = {
  h1: (props) => {
    const styles = useStyles(styleRefs)
    const { firstLastNoMargin } = useUtilStyles()

    return (
      <Text
        component="h3"
        variant="sans-light-20-32"
        className={clsx(styles.headingTarget, firstLastNoMargin)}
        {...props}
        styles={{ ...baseHeadingStyles, ...props.styles }}
      />
    )
  },
  h2: (props) => {
    const styles = useStyles(styleRefs)
    const { firstLastNoMargin } = useUtilStyles()

    return (
      <Text
        component="h4"
        variant="sans-medium-18-28"
        className={clsx(styles.headingTarget, firstLastNoMargin)}
        {...props}
        styles={{ ...baseHeadingStyles, ...props.styles }}
      />
    )
  },
  h3: (props) => {
    const styles = useStyles(styleRefs)
    const { firstLastNoMargin } = useUtilStyles()

    return (
      <Text
        component="h5"
        variant="sans-18-28"
        className={clsx(styles.headingTarget, firstLastNoMargin)}
        {...props}
        styles={{ ...baseHeadingStyles, ...props.styles }}
      />
    )
  },
  h4: (props) => {
    const styles = useStyles(styleRefs)
    const { firstLastNoMargin } = useUtilStyles()

    return (
      <Text
        component="h6"
        variant="sans-caps-semibold-13-18"
        className={clsx(styles.headingTarget, firstLastNoMargin)}
        {...props}
        styles={{
          ...baseHeadingStyles,
          marginBottom: baseTextStyles.marginBottom,
          ...props.styles,
        }}
      />
    )
  },
  h5: (props) => {
    const styles = useStyles(styleRefs)
    const { firstLastNoMargin } = useUtilStyles()

    return (
      <Text
        component="h6"
        variant="sans-caps-semibold-13-18"
        className={clsx(styles.headingTarget, firstLastNoMargin)}
        {...props}
        styles={{
          ...baseHeadingStyles,
          marginBottom: baseTextStyles.marginBottom,
          ...props.styles,
        }}
      />
    )
  },
  h6: (props) => {
    const styles = useStyles(styleRefs)
    const { firstLastNoMargin } = useUtilStyles()

    return (
      <Text
        component="h6"
        variant="sans-caps-semibold-13-18"
        className={clsx(styles.headingTarget, firstLastNoMargin)}
        {...props}
        styles={{
          ...baseHeadingStyles,
          marginBottom: baseTextStyles.marginBottom,
          ...props.styles,
        }}
      />
    )
  },
  p: (props) => {
    const { lastNoMargin } = useUtilStyles()

    return (
      <Text
        variant="sans-16-22"
        className={lastNoMargin}
        {...props}
        styles={{ ...baseTextStyles, ...props.styles }}
      />
    )
  },
  ul: (props) => {
    const { lastNoMargin } = useUtilStyles()

    return (
      <Box
        component="ul"
        className={lastNoMargin}
        {...props}
        styles={{
          ...baseTextStyles,
          paddingLeft: [7, 8],
          listStyle: 'disc',
          ...props.styles,
        }}
      />
    )
  },
  ol: (props) => {
    const { lastNoMargin } = useUtilStyles()

    return (
      <Box
        component="ol"
        className={lastNoMargin}
        {...props}
        styles={{
          ...baseTextStyles,
          paddingLeft: [7, 8],
          listStyle: 'decimal',
          ...props.styles,
        }}
      />
    )
  },
  li: ({ children, className, ...props }) => {
    const { lastNoMargin } = useUtilStyles()

    return (
      <Box
        component="li"
        {...props}
        className={clsx(styleRefs.listItem, lastNoMargin, className)}
        styles={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 2.5,
          ...props.styles,
        }}
      >
        <Text variant="sans-16-24">{children}</Text>
      </Box>
    )
  },
  a: ({ href, ...props }) => <Anchor href={href!} {...props} />,
  strong: (props) => (
    <Box
      component="strong"
      {...props}
      styles={{ fontWeight: 'semibold', ...props.styles }}
    />
  ),
}

export type HTMLContentProps = {
  html?: HTMLRendererProps['html']
  componentOverrides?: HTMLRendererProps['componentOverrides']
} & BoxProps

export const HTMLContent = ({
  html,
  componentOverrides,
  ...props
}: HTMLContentProps) => (
  <Box {...props}>
    <HTMLRenderer
      html={html}
      components={components}
      componentOverrides={componentOverrides}
    />
  </Box>
)
