import * as React from 'react'
import { Box, BoxProps, useBoxStyles } from '@walltowall/calico'

import { SVG } from './SVG'

import { ReactComponent as AssetIconArrowLeft } from '../assets/icon-arrow-left.svg'
import { ReactComponent as AssetIconArrowRight } from '../assets/icon-arrow-right.svg'
import { ReactComponent as AssetIconFacebookSquareSVG } from '../assets/icon-facebook-square.svg'
import { ReactComponent as AssetIconInstagramSquareSVG } from '../assets/icon-instagram-square.svg'
import { ReactComponent as AssetIconLinkedinSquareSVG } from '../assets/icon-linkedin-square.svg'
import { ReactComponent as AssetIconTwitterSquareSVG } from '../assets/icon-twitter-square.svg'
import { ReactComponent as AssetIconPlaySVG } from '../assets/icon-play.svg'

const icons = {
  arrowLeft: { svg: AssetIconArrowLeft, x: 42, y: 32 },
  arrowRight: { svg: AssetIconArrowRight, x: 42, y: 32 },
  play: { svg: AssetIconPlaySVG, x: 28, y: 24 },
  facebookSquare: {
    svg: AssetIconFacebookSquareSVG,
    x: 1,
    y: 1,
  },
  instagramSquare: {
    svg: AssetIconInstagramSquareSVG,
    x: 1,
    y: 1,
  },
  linkedinSquare: {
    svg: AssetIconLinkedinSquareSVG,
    x: 1,
    y: 1,
  },
  twitterSquare: {
    svg: AssetIconTwitterSquareSVG,
    x: 1,
    y: 1,
  },
}

export type IconProps = {
  name: keyof typeof icons
} & BoxProps

export const Icon = ({ name, ...props }: IconProps) => {
  const icon = icons[name]

  const svgClassName = useBoxStyles({ display: 'block' })

  return (
    <Box {...props}>
      <SVG svg={icon.svg} x={icon.x} y={icon.y} className={svgClassName} />
    </Box>
  )
}
