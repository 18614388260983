import React from 'react'
import { Box, BoxProps } from '@walltowall/calico'

const variants = {
  cover: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
} as const

export type GatsbyImageContainerProps = {
  variant?: keyof typeof variants
} & BoxProps

export const GatsbyImageContainer = ({
  variant: variantName,
  ...props
}: GatsbyImageContainerProps) => {
  const variant = variantName ? variants[variantName] : undefined

  return (
    <Box
      data-calico-gatsby-image-container=""
      {...props}
      styles={{
        ...variant,
        ...props.styles,
      }}
    />
  )
}
