import React from 'react'
import { useStyles } from 'react-treat'
import { Box, usePseudoBoxStyles, useBoxStyles } from '@walltowall/calico'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import { FluidObject } from 'gatsby-image'
import { AspectRatio } from '@walltowall/siamese'
import { SkipNavContent } from '@reach/skip-nav'
import VisuallyHidden from '@reach/visually-hidden'
import FocusTrap from 'focus-trap-react'
import clsx from 'clsx'

import { PageTemplateEnhancerProps } from '../templates/page'
import { useSiteSettings } from '../hooks/useSiteSettings'
import { useOrderedProfiles } from '../hooks/useOrderedProfiles'
import { useKeyPress } from '../hooks/useKeyPress'

import { BoundedBox } from '../components/BoundedBox'
import { GatsbyImageContainer } from '../components/GatsbyImageContainer'
import { HamburgerIcon } from '../components/HamburgerIcon'
import { Link } from '../components/Link'
import { SVG } from '../components/SVG'
import { Text } from '../components/Text'
import { ReactComponent as AssetLogoTextSVG } from '../assets/logo-text.svg'
import { ReactComponent as AssetLogoIconSVG } from '../assets/logo-icon.svg'

import * as styleRefs from './PageBodyHeader.treat'
import { chunk, noop } from '../utils'
import { ProfileMetadataFragment } from '../graphqlTypes'

interface ProfileProps {
  href: string
  title?: string
  focusable?: boolean
}

const Profile = ({ href, title, focusable = false }: ProfileProps) => {
  const backgroundColor = clsx(
    useBoxStyles({
      display: 'block',
      transitionDuration: 'normal',
      transitionTimingFunction: 'easeOut',
      transitionProperty: 'backgroundColor',
    }),
    usePseudoBoxStyles({ backgroundColor: 'beige90' }, 'focus'),
    usePseudoBoxStyles({ backgroundColor: 'beige90' }, 'hover'),
  )

  return (
    <Link
      href={href}
      className={backgroundColor}
      tabIndex={focusable ? undefined : -1}
    >
      <Box
        styles={{
          display: 'flex',
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 6,
          paddingRight: 6,
        }}
      >
        {title && <Text variant="sans-caps-semibold-13-18">{title}</Text>}
      </Box>
    </Link>
  )
}

interface DesktopProfileProps extends ProfileProps {
  imageAlt?: string
  imageFluid?: FluidObject
  name?: string
  setActiveProfile: (profile: ActiveDesktopProfileProps) => void
  isActive: boolean
}

const DesktopProfile = ({
  href,
  title,
  focusable = false,
  name,
  imageAlt,
  imageFluid,
  setActiveProfile,
  isActive,
}: DesktopProfileProps) => {
  const setSelfAsActive = () =>
    setActiveProfile({ imageAlt, imageFluid, title, name, href })

  return (
    <Link
      href={href}
      tabIndex={focusable ? undefined : -1}
      onMouseEnter={setSelfAsActive}
      onFocus={setSelfAsActive}
    >
      {title && (
        <Text
          variant="sans-caps-semibold-13-18"
          styles={{
            transitionDuration: 'normal',
            transitionTimingFunction: 'easeOut',
            transitionProperty: 'color',
            color: isActive ? 'fuschia30' : 'brown20',
          }}
          hoverStyles={{ color: 'fuschia30' }}
          focusStyles={{ color: 'fushcia30' }}
        >
          {title}
        </Text>
      )}
    </Link>
  )
}

type ActiveDesktopProfileProps = Omit<
  DesktopProfileProps,
  'focusable' | 'setActiveProfile' | 'isActive'
>

const ActiveDesktopProfile = ({
  imageAlt,
  imageFluid,
  name,
  title,
  href,
}: ActiveDesktopProfileProps) => {
  const linkStyles = clsx(
    useBoxStyles({
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: [8, null, null, 17],
      paddingRight: [8, null, null, 17],
      paddingTop: 15,
      paddingBottom: 15,
      marginTop: -15,
      marginBottom: -15,
      marginRight: [-19, null, null, -28],
      transitionDuration: 'normal',
      transitionTimingFunction: 'easeOut',
      transitionProperty: 'color',
    }),
    usePseudoBoxStyles({ color: 'fuschia30' }, 'hover'),
    usePseudoBoxStyles({ color: 'fuschia30' }, 'focus'),
  )

  return (
    <Link href={href} className={linkStyles}>
      {imageFluid && (
        <GatsbyImageContainer styles={{ backgroundColor: 'beige80' }}>
          <AspectRatio y={350} x={285}>
            <GatsbyImage fluid={imageFluid} alt={imageAlt ?? ''} />
          </AspectRatio>
        </GatsbyImageContainer>
      )}

      {name && (
        <Text variant="sans-16-22" styles={{ marginTop: 4 }}>
          {name}
        </Text>
      )}
      {title && (
        <Text variant="sans-caps-semibold-13-18" styles={{ marginTop: 3 }}>
          {title}
        </Text>
      )}
    </Link>
  )
}

interface DropdownProps {
  profiles: ProfileMetadataFragment[]
  isOpen: boolean
}

const DesktopDropdown = ({ profiles, isOpen }: DropdownProps) => {
  const styles = useStyles(styleRefs)

  const chunkedProfiles = React.useMemo(
    () => chunk(profiles, Math.ceil(profiles.length / 3)),
    // We know that profiles is static, so we can safely ignore it in our deps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const [activeProfile, setActiveProfile] =
    React.useState<ActiveDesktopProfileProps>(() => {
      const firstProfile = chunkedProfiles[0][0]

      return {
        imageAlt: firstProfile.data?.featured_image?.alt,
        imageFluid: firstProfile.data?.featured_image?.fluid,
        name: firstProfile.data?.title?.text,
        title: firstProfile.data?.profile_title?.text,
        href: firstProfile.url!,
      }
    })

  return (
    <Box
      component="nav"
      className={clsx(styles.shadow, styles.grid)}
      styles={{
        display: ['none', null, 'grid'],
        gap: 9,
        paddingLeft: [0, null, 19, 28],
        paddingRight: [0, null, 19, 28],
        paddingTop: 15,
        paddingBottom: 15,
      }}
    >
      {chunkedProfiles.map((profiles, idx) => (
        <React.Fragment key={idx}>
          <Box
            styles={{
              display: 'grid',
              gap: 7,
              alignContent: 'start',
              justifyItems: 'start',
            }}
          >
            {profiles.map(
              (profile) =>
                profile.url && (
                  <DesktopProfile
                    key={profile.url}
                    href={profile.url}
                    title={profile.data?.profile_title?.text}
                    name={profile.data?.title?.text}
                    imageAlt={profile.data?.featured_image?.alt}
                    imageFluid={profile.data?.featured_image?.fluid}
                    focusable={isOpen}
                    setActiveProfile={setActiveProfile}
                    isActive={activeProfile.href === profile.url}
                  />
                ),
            )}
          </Box>

          {idx !== 2 && <Box styles={{ backgroundColor: 'white' }} />}
        </React.Fragment>
      ))}

      <ActiveDesktopProfile {...activeProfile} />
    </Box>
  )
}

const MobileDropdown = ({ profiles, isOpen }: DropdownProps) => {
  const styles = useStyles(styleRefs)

  return (
    <Box
      component="ul"
      className={styles.shadow}
      styles={{
        display: ['block', null, 'none'],
      }}
    >
      {profiles.map(
        (profile) =>
          profile.url && (
            <Box
              key={profile.url}
              component="li"
              className={styles.lastNoBorder}
              styles={{
                borderStyle: 'solid',
                borderColor: 'white',
                borderWidth: 'none',
                borderBottomWidth: '1px',
              }}
            >
              <Profile
                href={profile.url}
                title={profile.data?.profile_title?.text}
                focusable={isOpen}
              />
            </Box>
          ),
      )}
    </Box>
  )
}

export type PageBodyHeaderProps = PageTemplateEnhancerProps

const PageBodyHeader = ({}: PageBodyHeaderProps) => {
  const [isOpen, toggleIsOpen] = React.useReducer((state) => !state, false)
  const close = isOpen ? toggleIsOpen : noop

  // focus-trap-react has an `escapeDeactivates` option, but it doesn't seem to
  // work. Need to handle ourselves.
  useKeyPress('Escape', close)

  const styles = useStyles(styleRefs)

  const siteSettings = useSiteSettings()
  const profiles = useOrderedProfiles()

  return (
    <>
      <Box component="header">
        <Box
          onClick={close}
          styles={{
            backgroundColor: 'brown20',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
            opacity: isOpen ? 75 : 0,
            pointerEvents: isOpen ? 'auto' : 'none',
            transitionProperty: 'opacity',
            transitionDuration: 'normal',
            transitionTimingFunction: 'easeOut',
          }}
        />

        <Box
          className={clsx(styles.gradientBackground, styles.shadow)}
          styles={{
            backgroundPositionX: 'left',
            backgroundPositionY: 'bottom',
            backgroundSize: 'cover',
            position: 'relative',
            zIndex: 3,
          }}
        >
          <BoundedBox
            styles={{
              color: 'white',
              paddingTop: [5, 6, 7],
              paddingBottom: [5, 6, 7],
              width: 'full',
              maxWidth: 'xlarge',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Box
              styles={{
                display: 'flex',
                justifyContent: 'spaceBetween',
                alignItems: 'center',
              }}
            >
              <Box
                styles={{
                  flexGrow: 1,
                  width: 'full',
                  marginRight: 4,
                  position: 'relative',
                }}
              >
                <Link href="/">
                  <VisuallyHidden>{siteSettings.siteName}</VisuallyHidden>
                  <Box
                    className={styles.logoIconTranslate}
                    styles={{
                      display: ['none', 'block'],
                      position: 'absolute',
                      marginLeft: [null, -8],
                      width: [null, '4.5rem', '5rem'],
                      top: 0,
                      left: 0,
                    }}
                  >
                    <SVG x={77} y={93} svg={AssetLogoIconSVG} />
                  </Box>
                  <Box styles={{ maxWidth: '16rem', marginLeft: [0, 12, 14] }}>
                    <SVG x={586.27} y={30} svg={AssetLogoTextSVG} />
                  </Box>
                </Link>
              </Box>

              <Box styles={{ flexShrink: 0 }}>
                <Box
                  component="button"
                  onClick={toggleIsOpen}
                  styles={{ display: 'block' }}
                >
                  <VisuallyHidden>
                    {isOpen ? 'Close navigation' : 'Open navigation'}
                  </VisuallyHidden>
                  <HamburgerIcon
                    isActive={isOpen}
                    styles={{ color: 'white' }}
                  />
                </Box>
              </Box>
            </Box>
          </BoundedBox>
        </Box>

        <BoundedBox
          className={clsx(!isOpen && styles.tuckAwayUpward)}
          styles={{
            maxWidth: 'xlarge',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            position: 'absolute',
            left: 0,
            right: 0,
            zIndex: 2,
            transitionProperty: 'transform',
            transitionDuration: 'normal',
            transitionTimingFunction: 'easeInOut',
            pointerEvents: 'none',
          }}
        >
          <FocusTrap
            active={isOpen}
            focusTrapOptions={{
              onDeactivate: close,
              allowOutsideClick: () => true,
            }}
          >
            <Box
              styles={{
                backgroundColor: 'beige80',
                width: 'full',
                maxWidth: ['30rem', null, 'xlarge'],
                pointerEvents: 'auto',
                marginLeft: 'auto',
              }}
            >
              <DesktopDropdown profiles={profiles} isOpen={isOpen} />
              <MobileDropdown profiles={profiles} isOpen={isOpen} />
            </Box>
          </FocusTrap>
        </BoundedBox>
      </Box>

      <SkipNavContent />
    </>
  )
}

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export default PageBodyHeader
