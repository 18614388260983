import React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import { Box, useBoxStyles } from '@walltowall/calico'
import { AspectRatio } from '@walltowall/siamese'
import { undefIfEmpty } from '@walltowall/helpers'

import { PageBodyQuoteFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { GatsbyImageContainer } from '../components/GatsbyImageContainer'
import { Text } from '../components/Text'

import * as styleRefs from './PageBodyQuote.treat'

export type PageBodyQuoteProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyQuote = ({
  quoteText,
  quotee,
  quoteeBusiness,
  imageFluid,
  imageFluidMobile,
  imageAlt,
  imageAltMobile,
}: PageBodyQuoteProps) => {
  const fullHeight = useBoxStyles({ height: 'full' })

  return (
    <Box
      component="section"
      styles={{
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
        backgroundColor: 'teal40',
        display: 'flex',
        flexDirection: ['column', 'row'],
      }}
    >
      <BoundedBox
        component="blockquote"
        styles={{
          textAlign: 'center',
          width: [null, '6/12'],
          position: 'relative',
        }}
      >
        <Box
          className={styleRefs.warriorBackground}
          styles={{
            opacity: 50,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        />
        <Box
          styles={{
            height: 'full',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <Text
            variant="serif-medium-italic-24-40"
            styles={{
              color: 'white',
              marginBottom: [6, null, 10, 12],
              fontStyle: 'italic',
            }}
          >
            “{quoteText}”
          </Text>

          <Box component="footer">
            <Text
              variant="sans-caps-semibold-13-18"
              styles={{ color: 'brown20', marginBottom: [2, null, 3, 4] }}
            >
              {quotee}
            </Text>
            <Text
              component="cite"
              variant="sans-caps-semibold-13-18"
              styles={{ color: 'brown20', fontStyle: 'normal' }}
            >
              {quoteeBusiness}
            </Text>
          </Box>
        </Box>
      </BoundedBox>

      <Box
        styles={{
          width: [null, '6/12'],
          flexShrink: 0,
          backgroundColor: 'brown20',
        }}
      >
        <GatsbyImageContainer styles={{ display: [null, 'none'] }}>
          <AspectRatio x={16} y={9}>
            {imageFluidMobile && (
              <GatsbyImage fluid={imageFluidMobile} alt={imageAltMobile} />
            )}
            {!imageFluidMobile && imageFluid && (
              <GatsbyImage fluid={imageFluid} alt={imageAlt} />
            )}
          </AspectRatio>
        </GatsbyImageContainer>
        <GatsbyImageContainer
          styles={{ display: ['none', 'block'], height: 'full' }}
        >
          <AspectRatio x={1} y={1} className={fullHeight}>
            {imageFluid && <GatsbyImage fluid={imageFluid} alt={imageAlt} />}
          </AspectRatio>
        </GatsbyImageContainer>
      </Box>
    </Box>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyQuoteFragment, typeof mapDataToContext>) => ({
  quoteText: undefIfEmpty(data.primary?.quote?.text),
  quotee: undefIfEmpty(data.primary?.quotee?.text),
  quoteeBusiness: undefIfEmpty(data.primary?.quotee_business?.text),
  imageFluid: data.primary?.image?.fluid,
  imageFluidMobile: data.primary?.image?.thumbnails?.mobile?.fluid,
  imageAlt: data.primary?.image?.alt,
  imageAltMobile: data.primary?.image?.thumbnails?.mobile?.alt,
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const fragment = graphql`
  fragment PageBodyQuote on PrismicPageBodyQuote {
    primary {
      quote {
        text
      }
      quotee {
        text
      }
      quotee_business {
        text
      }
      image {
        alt
        fluid(maxWidth: 500) {
          ...GatsbyPrismicImageFluid
        }
        thumbnails {
          mobile {
            alt
            fluid(maxWidth: 400) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  }
`

export default PageBodyQuote
