import React from 'react'
import { useStyles } from 'react-treat'
import { Box, BoxProps } from '@walltowall/calico'
import clsx from 'clsx'

import * as styleRefs from './FieldText.treat'

export type FieldTextProps = BoxProps & {
  name: string
  hasError?: boolean
}

export const FieldText = React.forwardRef<HTMLInputElement, FieldTextProps>(
  ({ type = 'text', hasError = false, ...props }, ref) => {
    const styles = useStyles(styleRefs)

    return (
      <Box
        ref={ref}
        component="input"
        type={type}
        className={clsx(styles.input, hasError && styles.errorPlaceholder)}
        {...props}
        styles={{
          display: 'block',
          backgroundColor: hasError ? 'maroon95' : 'white',
          borderColor: hasError ? 'maroon80' : 'beige80',
          borderRadius: '1.15rem',
          borderStyle: 'solid',
          borderWidth: '1px',
          color: 'brown0',
          flexGrow: 1,
          fontFamily: 'sans',
          paddingTop: 2,
          paddingBottom: 2,
          paddingLeft: 4,
          paddingRight: 4,
          outline: 'none',
          transitionDuration: 'normal',
          transitionProperty: 'borderColor',
          transitionTimingFunction: 'easeOut',
          width: 'full',
          maxWidth: 'full',

          ...props.styles,
        }}
        focusStyles={{
          borderColor: 'beige40',
        }}
      />
    )
  },
)
FieldText.displayName = 'FieldText'
