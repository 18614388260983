import React from 'react'
import GatsbyLink from 'gatsby-link'
import {
  Link as SiameseLink,
  LinkProps as SiameseLinkProps,
} from '@walltowall/siamese'
import { useSpeedbump } from '../hooks/useSpeedbump'
import { isSafeLink } from '../lib/isSafeLink'

const GatsbyLinkShim = ({ href, ...props }: { href: string }) => (
  <GatsbyLink to={href} {...props} />
)

export type LinkProps = Omit<SiameseLinkProps<'a'>, 'as'>

export const Link = ({ href, ...props }: LinkProps) => {
  let { openSpeedbump } = useSpeedbump()

  function handleClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (isSafeLink(href)) return

    openSpeedbump(href)
    e.preventDefault()
  }

  return (
    <SiameseLink
      routerLinkComponent={GatsbyLinkShim}
      onClick={handleClick}
      href={href}
      {...props}
    />
  )
}
