import { isInternal, isAnchorOnly } from '@walltowall/helpers'

const ALLOWED_HOST_NAME = 'hawaiinational'

export function isSafeLink(href: string): boolean {
  // #some-anchor
  if (href.startsWith('#')) {
    return true
  }

  // An anchor from Prismic
  if (isAnchorOnly(href)) {
    return true
  }

  // An internal relative link e.g. /about-us
  if (isInternal(href)) {
    return true
  }

  // Check if the hostname is hawaii national bank
  try {
    const url = new URL(href)

    if (url.hostname.includes(ALLOWED_HOST_NAME)) return true

    return false
  } catch {
    return false
  }
}
