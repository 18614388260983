import React, { useState } from 'react'
import { graphql } from 'gatsby'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@reach/accordion'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs'
import { Box, useBoxStyles, usePseudoBoxStyles } from '@walltowall/calico'
import { undefIfEmpty } from '@walltowall/helpers'
import clsx from 'clsx'

import { PageBodyToolsFragment } from '../graphqlTypes'
import { PageTemplateEnhancerProps } from '../templates/page'
import { MapDataToPropsArgs } from '../types'

import { BoundedBox } from '../components/BoundedBox'
import { Text } from '../components/Text'
import { Link } from '../components/Link'
import { Button } from '../components/Button'

type ItemProps = {
  name?: string
  description?: string
  href: string
  isActive?: boolean
  isLast?: boolean
}

const Item = ({
  name,
  description,
  href,
  isActive = false,
  isLast = false,
}: ItemProps) => {
  return (
    <AccordionItem>
      <AccordionButton>
        <Text
          component="h4"
          variant="serif-22-32"
          styles={{
            color: isActive ? 'fuschia30' : 'brown20',
            fontStyle: 'normal',
            marginBottom: 8,
            position: 'relative',
          }}
          hoverStyles={{ color: 'fuschia30' }}
          focusStyles={{ color: 'fuschia30' }}
        >
          <Box
            component="span"
            styles={{
              transitionProperty: 'borderColor',
              transitionDuration: 'normal',
              transitionTimingFunction: 'easeInOut',
            }}
          >
            {name}
          </Box>
        </Text>
      </AccordionButton>

      <AccordionPanel>
        <Text variant="sans-16-22" styles={{ marginBottom: 4, marginTop: -2 }}>
          {description}
        </Text>
        <Link href={href}>
          <Button
            component="button"
            variant="red"
            styles={{ marginBottom: isLast ? 0 : 8 }}
          >
            Learn More
          </Button>
        </Link>
      </AccordionPanel>
    </AccordionItem>
  )
}

export type PageBodyToolsProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyTools = ({ heading, items = [] }: PageBodyToolsProps) => {
  const [activeAccordionIdx, setAccordionIdx] = useState<number | undefined>(0)
  const [activeTabIdx, setTabIdx] = useState<number | undefined>(0)

  const accordionStyles = useBoxStyles({
    display: [null, 'none'],
  })
  const tabsStyles = useBoxStyles({
    display: ['none', 'block'],
  })
  const tabListStyles = useBoxStyles({
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: [-4, -6, -8],
    marginTop: [-12, null, null, -16],
    marginBottom: 12,
  })
  const tabStyles = useBoxStyles({
    display: 'flex',
    justifyContent: 'start',
    width: '4/12',
    paddingLeft: [4, 6, 8],
    paddingTop: [12, null, null, 16],
  })
  const hoverTabStyles = usePseudoBoxStyles(
    {
      color: 'fuschia30',
    },
    'hover',
  )
  const focusTabStyles = usePseudoBoxStyles(
    {
      color: 'fuschia30',
    },
    'focus',
  )
  const activeTabStyles = useBoxStyles({
    color: 'fuschia30',
  })
  const inactiveTabStyles = useBoxStyles({
    color: 'brown20',
  })

  return (
    <BoundedBox
      component="section"
      styles={{
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
        backgroundColor: 'gray80',
        color: 'brown20',
      }}
      innerMaxWidth="70rem"
    >
      <Text
        component="h4"
        variant="sans-30-52"
        styles={{ marginBottom: [8, 12, 16], width: '10/12', color: 'beige40' }}
      >
        {heading}
      </Text>

      {/* Mobile Selector */}
      <Accordion
        defaultIndex={activeAccordionIdx}
        onChange={setAccordionIdx}
        className={accordionStyles}
      >
        {items.map((item, idx) => (
          <Item
            key={idx}
            name={item.name}
            description={item.description}
            href={item.href!}
            isActive={activeAccordionIdx === idx}
            isLast={items.length - 1 === idx}
          />
        ))}
      </Accordion>

      {/* Desktop Selector */}
      <Tabs
        className={tabsStyles}
        onChange={setTabIdx}
        defaultIndex={activeTabIdx}
      >
        <TabList className={tabListStyles}>
          {items.map((item, idx) => (
            <Tab
              key={idx}
              className={clsx(
                tabStyles,
                hoverTabStyles,
                focusTabStyles,
                idx === activeTabIdx ? activeTabStyles : inactiveTabStyles,
              )}
            >
              <Text
                variant="serif-22-32"
                styles={{ position: 'relative' }}
                style={{ paddingBottom: '4px' }}
              >
                <Box
                  component="span"
                  styles={{
                    transitionProperty: 'borderColor',
                    transitionDuration: 'normal',
                    transitionTimingFunction: 'easeInOut',
                  }}
                >
                  {item.name}
                </Box>
              </Text>
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {items.map((item, idx) => (
            <TabPanel key={idx}>
              <Text variant="sans-16-22" styles={{ marginBottom: 8 }}>
                {item.description}
              </Text>
              <Link href={item.href!}>
                <Button variant="red">Learn More</Button>
              </Link>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </BoundedBox>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyToolsFragment, typeof mapDataToContext>) => ({
  heading: undefIfEmpty(data.primary?.heading?.text),
  items: data.items?.map((item) => ({
    name: undefIfEmpty(item?.name?.text),
    description: undefIfEmpty(item?.description?.text),
    href: item?.href?.url,
  })),
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyTools on PrismicPageBodyTools {
    primary {
      heading {
        text
      }
    }
    items {
      name {
        text
      }
      description {
        text
      }
      href {
        url
      }
    }
  }
`

export default PageBodyTools
