import * as React from 'react'
import { graphql } from 'gatsby'
import VisuallyHidden from '@reach/visually-hidden'
import { Box } from '@walltowall/calico'
import { useKeenSlider } from 'keen-slider/react'
import { useTransition, a } from 'react-spring'

import { PageBodyAskFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { Text } from '../components/Text'
import { Stack } from '../components/Stack'
import { Icon } from '../components/Icon'
import { Inline } from '../components/Inline'

export type PageBodyAskProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyAsk = ({ children, ...props }: PageBodyAskProps) => {
  const sets = React.Children.toArray(children)
  const setCount = sets.length

  const [askSetIndex, setAskSetIndex] = React.useState(0)
  const goToNextSet = () => setAskSetIndex((askSetIndex + 1) % setCount)

  const transition = useTransition(askSetIndex, {
    from: {
      opacity: 0,
      transform: 'translate3d(10%,0,0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0%,0,0)',
      delay: 200,
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(-10%,0,0)',
    },
  })
  const fragment = transition((styles, item) => (
    <a.div style={{ ...styles, position: 'absolute', width: '100%' }}>
      <Box>
        {React.cloneElement(sets[item], {
          goToNextSet,
          nextSetQuestion: sets[(item + 1) % setCount].props.question,
        })}
      </Box>
    </a.div>
  ))

  return (
    <BoundedBox
      component="section"
      innerMaxWidth="large"
      variant="short"
      styles={{
        maxWidth: 'xlarge',
        marginRight: 'auto',
        marginLeft: 'auto',
        backgroundColor: 'green50',
        color: 'white',
        overflow: 'hidden',
        position: 'relative',
      }}
      {...props}
    >
      <Text
        variant="sans-caps-semibold-13-18"
        styles={{ color: 'brown20', marginBottom: [5, 6] }}
      >
        Ask an Entrepreneur
      </Text>
      <Box styles={{ position: 'relative' }}>{fragment}</Box>
      <Box
        styles={{
          display: 'flex',
          visibility: 'hidden',
          pointerEvents: 'none',
        }}
      >
        {React.Children.map(
          children,
          (child, index) =>
            React.isValidElement(child) && (
              <Box
                styles={{
                  /* transitionDuration: 'slow', */
                  transitionTimingFunction: 'easeInOut',
                  width: 'full',
                  maxHeight:
                    index === askSetIndex
                      ? ['35rem', '50rem', '60rem']
                      : ['15rem', '30rem', '40rem'],
                  transitionProperty: 'maxHeight',
                  willChange: 'maxHeight',
                }}
                style={{ transitionDuration: '500ms' }}
              >
                {React.cloneElement(child, {
                  nextSetQuestion: sets[(index + 1) % setCount].props.question,
                })}
              </Box>
            ),
        )}
      </Box>
    </BoundedBox>
  )
}

type PageBodyAskSetProps = {
  question?: string
  children?: React.ReactNode
  nextSetQuestion?: string
  goToNextSet?: () => void
}

const PageBodyAskSet = ({
  question,
  children,
  nextSetQuestion,
  goToNextSet,
}: PageBodyAskSetProps) => {
  const [isOnFirstSlide, setIsOnFirstSlide] = React.useState(true)
  const [isOnLastSlide, setIsOnLastSlide] = React.useState(false)

  const [sliderRef, slider] = useKeenSlider({
    mode: 'free-snap',
    slidesPerView: 1.25,
    spacing: 16,
    breakpoints: {
      '(min-width: 35rem)': {
        slidesPerView: 2.25,
        spacing: 24,
      },
      '(min-width: 48rem)': {
        slidesPerView: 2.25,
        spacing: 24,
      },
    },
    slideChanged: (s) => {
      const details = s.details()
      setIsOnFirstSlide(details.absoluteSlide === 0)
      setIsOnLastSlide(
        details.absoluteSlide >= details.size - details.slidesPerView,
      )
    },
  })

  return (
    <Box>
      <Text
        variant="serif-medium-italic-24-40"
        styles={{ marginBottom: [6, 13, 20], width: [null, '10/12', '9/12'] }}
      >
        {question}
      </Text>
      <Box
        ref={sliderRef}
        className="keen-slider"
        styles={{
          marginBottom: [6, 10, 15],
          overflow: 'visible',
          alignItems: 'start',
        }}
      >
        {children}
        <Box className="keen-slider__slide" styles={{ alignSelf: 'stretch' }}>
          <Box
            component="button"
            onClick={goToNextSet}
            styles={{
              backgroundColor: 'teal40',
              color: 'white',
              width: 'full',
              height: 'full',
              paddingTop: [10, 18, 25],
              paddingLeft: [8, 14, 20],
              paddingRight: [8, 14, 20],
              paddingBottom: [8, 14, 20],
              transitionDuration: 'normal',
              transitionProperty: 'opacity',
              transitionTimingFunction: 'easeOut',
            }}
            focusStyles={{ opacity: 85 }}
            hoverStyles={{ opacity: 85 }}
          >
            <Box
              styles={{
                height: 'full',
                display: 'flex',
                /* justifyContent: 'center', */
                flexDirection: 'column',
              }}
            >
              <Text
                variant="sans-caps-semibold-13-18"
                styles={{ width: 'full', marginBottom: [5, 6] }}
              >
                Next
              </Text>
              <Text
                variant="sans-16-24"
                styles={{ flexGrow: 1, marginBottom: [8, 10, 12] }}
              >
                {nextSetQuestion}
              </Text>
              <Icon
                name="arrowRight"
                styles={{
                  width: ['1.375rem', '2.5rem'],
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        styles={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          alignItems: [null, 'center'],
          justifyContent: [null, 'spaceBetween'],
        }}
      >
        <Inline space={[4, 6, 8]} styles={{ marginBottom: [6, 0] }}>
          <Box
            component="button"
            onClick={slider?.prev}
            disabled={isOnFirstSlide}
            styles={{
              opacity: isOnFirstSlide ? 25 : undefined,
              cursor: isOnFirstSlide ? 'default' : undefined,
              transitionProperty: 'opacity',
              transitionDuration: 'normal',
              transitionTimingFunction: 'easeOut',
            }}
            hoverStyles={{ color: isOnFirstSlide ? undefined : 'gray80' }}
            focusStyles={{ color: isOnFirstSlide ? undefined : 'gray80' }}
          >
            <VisuallyHidden>Go to previous answer</VisuallyHidden>
            <Icon
              name="arrowLeft"
              styles={{
                width: ['1.375rem', '2.5rem'],
                transitionProperty: 'color',
                transitionDuration: 'normal',
              }}
            />
          </Box>
          <Box
            component="button"
            onClick={slider?.next}
            disabled={isOnLastSlide}
            styles={{
              opacity: isOnLastSlide ? 25 : undefined,
              cursor: isOnLastSlide ? 'default' : undefined,
              transitionProperty: 'opacity',
              transitionDuration: 'normal',
              transitionTimingFunction: 'easeOut',
            }}
            hoverStyles={{ color: isOnLastSlide ? undefined : 'gray80' }}
            focusStyles={{ color: isOnLastSlide ? undefined : 'gray80' }}
          >
            <VisuallyHidden>Go to next answer</VisuallyHidden>
            <Icon
              name="arrowRight"
              styles={{
                width: ['1.375rem', '2.5rem'],
                transitionProperty: 'color',
                transitionDuration: 'normal',
              }}
            />
          </Box>
        </Inline>
        <Box
          component="button"
          onClick={goToNextSet}
          styles={{
            width: [null, '5/12', '4/12'],
            transitionDuration: 'normal',
            transitionProperty: 'colorOpacity',
            transitionTimingFunction: 'easeOut',
            opacity: isOnLastSlide ? 0 : 100,
            pointerEvents: isOnLastSlide ? 'none' : 'auto',
          }}
          disabled={isOnLastSlide}
          focusStyles={{ color: 'gray80' }}
          hoverStyles={{ color: 'gray80' }}
        >
          <Inline
            space={[2.5, 4]}
            align={[null, 'end']}
            alignY="baseline"
            wrap={false}
            styles={{ position: 'relative' }}
          >
            <Text
              variant="sans-caps-semibold-13-18"
              styles={{ color: 'brown20' }}
            >
              Next:
            </Text>
            <Text variant="sans-16-24">{nextSetQuestion}</Text>
          </Inline>
        </Box>
      </Box>
    </Box>
  )
}
PageBodyAsk.Set = PageBodyAskSet

type PageBodyAskSetAnswerProps = {
  profileName?: string
  profileTitle?: string
  children?: string
}

const PageBodyAskSetAnswer = ({
  profileName,
  profileTitle,
  children,
}: PageBodyAskSetAnswerProps) => (
  <Box
    className="keen-slider__slide"
    styles={{
      backgroundColor: 'gray80',
      color: 'brown20',
      padding: [8, 14],
    }}
  >
    <Stack space={[5, 9, 10]}>
      {children && (
        <Text variant="sans-16-18" styles={{ textAlign: 'center' }}>
          {children}
        </Text>
      )}
      <Stack space={2.5}>
        {profileName && (
          <Text
            variant="sans-caps-semibold-13-18"
            styles={{ textAlign: 'center' }}
          >
            {profileName}
          </Text>
        )}
        {profileTitle && (
          <Text
            variant="sans-caps-semibold-13-18"
            styles={{ textAlign: 'center' }}
          >
            {profileTitle}
          </Text>
        )}
      </Stack>
    </Stack>
  </Box>
)
PageBodyAsk.SetAnswer = PageBodyAskSetAnswer

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageBodyAskFragment, typeof mapDataToContext>) => ({
  children: data.items?.map((item) => {
    const askSet = item?.ask_set?.document

    return (
      <PageBodyAsk.Set
        key={askSet?.uid}
        question={askSet?.data?.question?.text}
      >
        {askSet?.data?.answers?.map((answer) => (
          <PageBodyAsk.SetAnswer
            key={answer?.answer?.text}
            profileName={answer?.profile_name?.text}
            profileTitle={answer?.profile_title?.text}
          >
            {answer?.answer?.text}
          </PageBodyAsk.SetAnswer>
        ))}
      </PageBodyAsk.Set>
    )
  }),
})

export const mapDataToContext = () => ({
  bg: 'green50',
})

export const fragment = graphql`
  fragment PageBodyAsk on PrismicPageBodyAsk {
    items {
      ask_set {
        document {
          ... on PrismicAskSet {
            uid
            data {
              question {
                text
              }
              answers {
                answer {
                  text
                }
                profile_name {
                  text
                }
                profile_title {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PageBodyAsk
