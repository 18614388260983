/**
 * Root Prismic slices file where Page Body slices are registered for use in the
 * page template file.
 *
 * @see /docs/guide-create-a-slice.md for more details.
 */

import { graphql } from 'gatsby'
import { map } from 'fp-ts/es6/Record'

// 1. Import your slice
import * as PageBodyFooter from './PageBodyFooter'
import * as PageBodyHeader from './PageBodyHeader'
import * as PageBodyText from './PageBodyText'
import * as PageBodyHero from './PageBodyHero'
import * as PageBodyStartsWith from './PageBodyStartsWith'
import * as PageBodyQuote from './PageBodyQuote'
import * as PageBodyProfilesList from './PageBodyProfilesList'
import * as PageBodyCallToAction from './PageBodyCallToAction'
import * as PageBodyTools from './PageBodyTools'
import * as PageBodyAsk from './PageBodyAsk'
import * as PageBodyContactForm from './PageBodyContactForm'

// 2. Add your slice
const slices = {
  PageBodyFooter,
  PageBodyHeader,
  PageBodyText,
  PageBodyHero,
  PageBodyStartsWith,
  PageBodyQuote,
  PageBodyProfilesList,
  PageBodyCallToAction,
  PageBodyTools,
  PageBodyAsk,
  PageBodyContactForm,
} as const

// 3. Add your slice fragment
export const fragment = graphql`
  fragment SlicesPageBody on PrismicPageBodySlicesType {
    ...PageBodyText
    ...PageBodyHero
    ...PageBodyStartsWith
    ...PageBodyQuote
    ...PageBodyProfilesList
    ...PageBodyCallToAction
    ...PageBodyTools
    ...PageBodyAsk

    # The following slices do not have fragments:
    #...PageBodyFooter
    #...PageBodyHeader
    #...PageBodyContactForm
  }
`

type ValueOf<T> = T[keyof T]

export const slicesMap = map((module: ValueOf<typeof slices>) => {
  const component: typeof module.default & {
    mapDataToProps?: any
    mapDataToContext?: any
  } = module.default
  if ('mapDataToProps' in module)
    component.mapDataToProps = module.mapDataToProps
  if ('mapDataToContext' in module)
    component.mapDataToContext = module.mapDataToContext
  return component
})(slices)
