import { graphql, useStaticQuery } from 'gatsby'

import {
  UseOrderedProfilesQuery,
  ProfileMetadataFragment,
} from '../graphqlTypes'

export const useOrderedProfiles = () => {
  const queryData = useStaticQuery<UseOrderedProfilesQuery>(graphql`
    query UseOrderedProfiles {
      prismicPage(uid: { eq: "home" }) {
        data {
          body {
            ... on PrismicPageBodyProfilesList {
              items {
                profile {
                  document {
                    ...ProfileMetadata
                  }
                }
              }
            }
          }
        }
      }
    }

    fragment ProfileMetadata on PrismicProfile {
      uid
      url
      data {
        title {
          text
        }
        profile_title {
          text
        }
        featured_image {
          alt
          fluid(maxWidth: 200) {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }
  `)

  const slices = queryData.prismicPage?.data?.body
  if (!slices) return []

  return slices.reduce((acc, slice) => {
    const profileDocs = (
      slice?.items?.map?.(
        (item) => item?.profile?.document as ProfileMetadataFragment,
      ) ?? []
    ).filter(Boolean)

    return [...acc, ...profileDocs]
  }, [] as ProfileMetadataFragment[])
}
