import React from 'react'
import ConditionalWrap from 'conditional-wrap'
import { Box } from '@walltowall/calico'
import VisuallyHidden from '@reach/visually-hidden'

import { Text } from './Text'

import type { BoxProps } from '@walltowall/calico'

type FieldProps = {
  label: string
  displayLabel?: boolean
  isRequired?: boolean
  children: React.ReactNode
  labelDirection?: NonNullable<BoxProps['styles']>['flexDirection']
  hasError?: boolean
  error?: string
} & Omit<BoxProps, 'name'>

export const Field = ({
  label,
  children,
  displayLabel = true,
  isRequired = false,
  labelDirection = 'column',
  hasError = false,
  error,
  ...props
}: FieldProps) => {
  return (
    <Box
      component="label"
      {...props}
      styles={{ display: 'block', ...props.styles }}
    >
      <Box
        styles={{
          display: 'flex',
          flexDirection: labelDirection,
          alignItems: labelDirection === 'column' ? undefined : 'center',
        }}
      >
        <ConditionalWrap
          condition={!displayLabel}
          wrap={(children) => <VisuallyHidden>{children}</VisuallyHidden>}
        >
          <Text
            component="span"
            variant="sans-16"
            styles={{
              display: 'block',
              fontWeight: 'medium',
              marginBottom: labelDirection === 'column' ? [2, 3] : undefined,
              marginRight: labelDirection === 'row' ? [3, 4] : undefined,
              color: hasError ? 'maroon40' : 'brown20',
            }}
          >
            {label}
            {isRequired && (
              <Box
                component="span"
                styles={{ display: 'inline', color: 'beige40', marginLeft: 1 }}
              >
                (required)
              </Box>
            )}
          </Text>
        </ConditionalWrap>
        <Box
          styles={{
            marginLeft: labelDirection === 'column' ? -1 : undefined,
            marginRight: -1,
          }}
        >
          {children}
        </Box>
      </Box>
      {hasError && (
        <Text
          variant="sans-12"
          styles={{ marginTop: [2, 3], color: 'maroon40' }}
        >
          {error}
        </Text>
      )}
    </Box>
  )
}
