import React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'

import { PageBodyCallToActionFragment } from '../graphqlTypes'
import { PageTemplateEnhancerProps } from '../templates/page'
import { MapDataToPropsArgs } from '../types'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { Button } from '../components/Button'
import { Link } from '../components/Link'
import { Inline } from '../components/Inline'

export type PageBodyCallToActionProps = ReturnType<typeof mapDataToProps> &
  PageTemplateEnhancerProps

const PageBodyCallToAction = ({
  textHTML,
  buttons = [],
}: PageBodyCallToActionProps) => (
  <BoundedBox
    component="section"
    styles={{
      maxWidth: 'xlarge',
      marginRight: 'auto',
      marginLeft: 'auto',
      backgroundColor: 'gray80',
    }}
  >
    {textHTML && (
      <HTMLContent
        html={textHTML}
        styles={{
          textAlign: 'center',
          marginBottom: [8, null, 10, 12],
          color: 'brown20',
        }}
        componentOverrides={{
          h1: (Comp) => (props) =>
            (
              <Comp
                variant="sans-30-52"
                styles={{ color: 'beige40' }}
                {...props}
              />
            ),
          p: (Comp) => (props) =>
            (
              <Comp
                variant="sans-18-28"
                style={{ maxWidth: '40ch', marginRight: 'auto' }}
                styles={{ marginLeft: 'auto' }}
                {...props}
              />
            ),
        }}
      />
    )}

    <Inline space={[3, 6, 8, 10]} align="center">
      {buttons.map((b, idx) => (
        <Link key={idx} href={b.href?.url!}>
          <Button component="div" variant="red">
            {b.text?.text}
          </Button>
        </Link>
      ))}
    </Inline>
  </BoundedBox>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<
  PageBodyCallToActionFragment,
  typeof mapDataToContext
>) => ({
  textHTML: getRichText(data.primary?.text),
  buttons: data.items?.map((item) => ({
    text: item?.button_text,
    href: item?.button_link,
  })),
})

export const mapDataToContext = () => ({})

export const fragment = graphql`
  fragment PageBodyCallToAction on PrismicPageBodyCallToAction {
    primary {
      text {
        text
        html
      }
    }
    items {
      button_text {
        text
      }
      button_link {
        url
      }
    }
  }
`

export default PageBodyCallToAction
