import * as React from 'react'
import { Box } from '@walltowall/calico'
import { object, string } from 'nope-validator'
import { useForm } from 'react-hook-form'
import { nopeResolver } from '@hookform/resolvers/nope'
import { postNetlifyForm } from '@walltowall/helpers'

import { BoundedBox } from '../components/BoundedBox'
import { Text } from '../components/Text'

import type { PageTemplateEnhancerProps } from '../templates/page'
import { FieldText } from '../components/FieldText'
import { Field } from '../components/Field'
import { FieldTextarea } from '../components/FieldTextarea'
import { Columns } from '../components/Columns'
import { Button } from '../components/Button'

const NETLIFY_FORM_NAME = 'Contact Us'

interface FormSchema {
  firstName: string
  lastName: string
  email: string
  phoneNumber?: string
  businessName?: string
  message: string
}

export type PageBodyContactFormProps = PageTemplateEnhancerProps

const formValidator = object().shape({
  firstName: string().required('First name is required.'),
  lastName: string().required('Last name is required.'),
  email: string()
    .email('Must provide valid email.')
    .required('Email is required.'),
  phoneNumber: string(),
  businessName: string(),
  message: string().required('Message is required.'),
})

const formResolver = nopeResolver(formValidator) as () => any

const PageBodyContactForm = ({ nextSharesBg }: PageBodyContactFormProps) => {
  const {
    register,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
  } = useForm<FormSchema>({ resolver: formResolver, reValidateMode: 'onBlur' })

  const onSubmit = async (data: FormSchema) => {
    await postNetlifyForm(NETLIFY_FORM_NAME, data)
  }

  return (
    <BoundedBox
      component="section"
      nextSharesBg={nextSharesBg}
      innerMaxWidth="medium"
      styles={{
        maxWidth: 'xlarge',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: 'white',
        color: 'brown20',
      }}
    >
      <Box
        styles={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Text
          component="h2"
          variant="sans-30-56"
          styles={{
            color: 'beige40',
            textAlign: ['left', 'center'],
            marginBottom: [8, 12],
          }}
        >
          {isSubmitSuccessful
            ? 'Thank you for your message!'
            : 'How can we help you?'}
        </Text>

        {isSubmitSuccessful && (
          <Text
            component="p"
            variant="sans-20"
            styles={{ color: 'brown20', textAlign: ['left', 'center'] }}
          >
            Someone from our team will be in contact with you.
          </Text>
        )}

        {isSubmitSuccessful ? null : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            name={NETLIFY_FORM_NAME}
            data-netlify
          >
            <Box styles={{ marginBottom: [8, 10] }}>
              <Columns
                count={[1, 2]}
                space={[5, 6]}
                styles={{ marginBottom: [5, 6] }}
              >
                <Field
                  label="First Name"
                  isRequired
                  hasError={Boolean(errors.firstName)}
                  error={errors.firstName?.message}
                >
                  <FieldText
                    placeholder="First name"
                    hasError={Boolean(errors.firstName)}
                    {...register('firstName')}
                  />
                </Field>

                <Field
                  label="Last Name"
                  isRequired
                  hasError={Boolean(errors.lastName)}
                  error={errors.lastName?.message}
                >
                  <FieldText
                    placeholder="Last name"
                    hasError={Boolean(errors.lastName)}
                    {...register('lastName')}
                  />
                </Field>

                <Field
                  label="Email address"
                  isRequired
                  hasError={Boolean(errors.email)}
                  error={errors.email?.message}
                >
                  <FieldText
                    placeholder="Email address"
                    hasError={Boolean(errors.email)}
                    {...register('email')}
                  />
                </Field>

                <Field
                  label="Phone Number"
                  hasError={Boolean(errors.phoneNumber)}
                  error={errors.phoneNumber?.message}
                >
                  <FieldText
                    placeholder="Phone number"
                    hasError={Boolean(errors.phoneNumber)}
                    {...register('phoneNumber')}
                  />
                </Field>

                <Field
                  label="Business Name"
                  hasError={Boolean(errors.businessName)}
                  error={errors.businessName?.message}
                >
                  <FieldText
                    placeholder="Business Name"
                    hasError={Boolean(errors.businessName)}
                    {...register('businessName')}
                  />
                </Field>
              </Columns>

              <Field
                label="Message"
                isRequired
                hasError={Boolean(errors.message)}
                error={errors.message?.message}
              >
                <FieldTextarea
                  placeholder="Your message"
                  hasError={Boolean(errors.message)}
                  {...register('message')}
                />
              </Field>
            </Box>

            <Box
              styles={{
                display: 'flex',
                alignItems: ['start', 'center'],
                flexDirection: ['column', 'row'],
              }}
            >
              <Text
                variant="sans-16"
                styles={{
                  color: 'brown20',
                  marginRight: [0, 4],
                  marginBottom: [6, 0],
                }}
              >
                <Box
                  component="strong"
                  styles={{ display: 'inline', fontWeight: 'semibold' }}
                >
                  Important
                </Box>
                : For your security, please do not send any confidential
                personal or account information through this form, or via email;
                including Social Security Number, Account Number, Date of Birth,
                Password, and/or PIN Number.
              </Text>

              <Button variant="red" type="submit">
                Send
              </Button>
            </Box>
          </form>
        )}
      </Box>
    </BoundedBox>
  )
}

export const mapDataToContext = () => ({
  bg: 'white',
})

export default PageBodyContactForm
